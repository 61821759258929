export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Being an AI company is about more than just using new tools. It is a
            way to change your belief in what is possible. Artificial
            Intelligence builds on top of your information technology
            infrastructure and our solutions engineers will help you understand
            how to make the most of what you have today, while enhancing your
            compliance and information security. Planar AI can assist you with
            product development, process refinement, analytics and staff
            upskilling, to enable your digital transformation.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-12">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
